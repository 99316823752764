export const ActionTypes = {
  PRODUCTS_LOADING: 'PRODUCTS_LOADING',
  PRODUCTS_LOADED: 'PRODUCTS_LOADED',
}

export const productsLoaded = (payload) => ({
  type: ActionTypes.PRODUCTS_LOADED,
  payload,
})

export const productsLoading = () => {
  type: ActionTypes.PRODUCTS_LOADING
}

