import { connect } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react'
import useFetch from 'use-http'
import ReactS3Uploader from 'react-s3-uploader'
import { ProgressBar } from 'react-step-progress-bar'

import { Button } from '@kyper/button'
import { Card } from '@kyper/card'
import { MessageBox } from '@kyper/messagebox'
import { Select } from '@kyper/select'
import { TextInput } from '@kyper/input'
import { Text } from '@kyper/text'

import { askLoaded } from '../../actions/NewAskView'

import { checkForMobile } from '../../utils/browser'
import { Validation } from '../../utils/Validation'

import { TextArea } from '../shared/TextArea'

const url = '/api/v1/requests'

const Story = ({ ask, askLoaded, onNext }) => {
  const [imageSuccess, setImageSuccess] = useState(false)
  const [imageError, setImageError] = useState(false)
  const [imageUrl, setImageUrl] = useState(ask.image_url)
  const [summary, setSummary] = useState(ask.summary || '')
  const [uploadingImage, setUploadingImage] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [errors, setErrors] = useState({})

  const styles = getStyles()
  const isMobile = checkForMobile()

  const { post, put, response, loading, error } = useFetch(url, { cachePolicy: 'no-cache' })

  const handleNext = useCallback(async () => {
    const schema = {
      summary: val => {
        if (val.length < 20) {
          return 'Description must be at least 20 characters long.'
        }

        if (val.length > 500) {
          return 'Description can not be greater than 500 characters long'
        }

        // check for something that resembles a URL
        let re = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gm
        let regex = new RegExp(re)

        if (val.match(regex)) {
          return 'It looks like you have an external URL. Please remove it and add it as a follow up comment once your Ask has been approved.'
        }

        // Check for the following words
        re = /cash|gift card|giftcard|wire transfer|money transfer|money|dollar/gim
        regex = new RegExp(re)

        if (val.match(regex)) {
          return "It looks like you're asking for cash or gift cards which is not allowed."
        }

        re = /\$([0-9])/gm
        regex = new RegExp(re)

        if (val.match(regex)) {
          return "You've mentioned something that looks like a dollar amount. Please remove it."
        }

        return ''
      }
    }

    const check = Validation.validate(schema, { summary }) || {}
    setErrors(check)

    if (Object.keys(check).length) {
      return
    }

    if (ask.id) {
      const updatedAsk = await put(`/${ask.id}`, {
        ...ask, 
        summary, 
        image_url : imageUrl
      })

      if (response.ok) {
        askLoaded(updatedAsk)
        onNext()
      }
    } else {
      const newAsk = await post({ summary, image_url: imageUrl })

      if (response.ok) {
        askLoaded(newAsk)
        onNext()
      }
    }

  }, [post, response, summary, imageUrl])


  return (
    <React.Fragment>
      <Card style={styles.card} padding={isMobile ? 'small' : 'large'}>
        <div style={styles.row}>
          <Text as="H2" tag="h2">
            Your Story
          </Text>
          <Text as="Paragraph" tag="p">
            In the box below, give the Intellihelp Angels a glimpse into who you are and your current situation. Let them know why you're in need.
            You will select your items on the next page. 
          </Text>
        </div>
        <div style={styles.row}>
          <TextArea
            error={errors.summary}
            label="Description"
            name="summary"
            onChange={e => {setSummary(e.target.value)}}
            rows={isMobile ? 5 : 10}
            value={summary}
          />
          <br />
          <br />
          <Text as="H3" tag="h3">
            Optional Image
          </Text>
          <Text as="Paragraph" tag="p">
            You may attach a single image to your post to make it more personable.
          </Text>
          <br />
          {!imageSuccess && !imageUrl ? (
            <ReactS3Uploader
              signingUrl="/api/v1/request_sign"
              signingUrlMethod="GET"
              accept="image/*"
              onError={() => { 
                setImageError(true)
                setUploadingImage(false)
              }}
              onFinish={(signResult) => { 
                setImageUrl(signResult.signedUrl.split('?')[0])
                setUploadingImage(false)
                setImageSuccess(true)
              }}
              onProgress={percent => {
                setUploadingImage(true)
                setUploadProgress(percent)
              }}
              contentDisposition="auto"
              scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/ig, '')}
              uploadRequestHeaders={{}}
              autoUpload={true}
            />
          ) : null}

          { imageSuccess && imageUrl ? (
            <MessageBox title="Image Uploaded" variant="success">
              You have successfully uploaded your image.
            </MessageBox>
          ) : null}

          {uploadingImage ? (
            <ProgressBar filledBackground="linear-gradient(to right, #fefb72, #f0bb31)" percent={uploadProgress} />
          ) : null}
        </div>

        {imageUrl && uploadingImage === false ? (
          <div style={styles.imageContainer}>
            <Button variant='destructive' onClick={() => setImageUrl('')}>
              Delete Image
            </Button>
            <br />
            <img src={imageUrl} style={styles.image} />
          </div>
        ) : null}

      </Card>
      {error ? (
        <MessageBox title="Saving Error" variant="error">
          There was an error saving your data, please try again later.
        </MessageBox>
      ): null}
      {imageError ? (
        <MessageBox title="Saving Error" variant="error">
          There was an error uploading your image.
        </MessageBox>
      ): null}
      <Button disabled={uploadingImage || loading} onClick={handleNext} style={styles.button} variant="primary">Next</Button>
    </React.Fragment>
  )
}

const mapDispatchToProps = dispatch => ({
  askLoaded: payload => dispatch(askLoaded(payload)),
})

const mapStateToProps = state => ({
  ask: state.newAskView.ask
})

export default connect(mapStateToProps, mapDispatchToProps)(Story)

const getStyles = () => ({
  row: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 25,
  },
  button: {
    marginTop: 25,
  },
  imageContainer: {
    position: 'relative',
    marginTop: 20,
  },
  image: {
    maxWidth: '100%',
  }
})

