import _groupBy from 'lodash/groupBy'

import { ActionTypes } from '../actions/Products'

export const defaultState = {
  items: [],
  loading: false,
}

const productsLoading = state => ({ ...state, loading: true })

const productsLoaded = (state, action) => ({
  ...state,
  loading: false,
  items: _groupBy(action.payload, 'product_type'),
})

export const products = (state = defaultState, action) => {
  switch(action.type) {
    case  ActionTypes.PRODUCTS_LOADED:
      return productsLoaded(state, action)
    case  ActionTypes.PRODUCTS_LOADING:
      return productsLoading(state)
    default:
      return state
  }
}
