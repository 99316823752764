import React from 'react'

import { Button } from '@kyper/button'
import { Card } from '@kyper/card'
import { useTokens } from '@kyper/tokenprovider'
import { Text } from '@kyper/text'

export const RequestCard = ({ request }) => {
  const tokens = useTokens()
  const styles = getStyles(tokens)

  return (
    <Card padding="none" style={styles.card}>
      <div style={styles.blue} />
      <div style={styles.imageContainer}>
        <img alt="Askers image" src={request.image_url} style={styles.image} />
      </div>
      <div style={styles.container}>
        <Text as="H2" tag="h2" style={{ textAlign: 'center' }}>
          {request.user.first_name}
        </Text>
        <Text as="ParagraphSmall" tag="p" style={{ textAlign: 'center' }}>
          {request.user.location.city} {request.user.location.state}
        </Text>
        <Text as="Paragraph" tag="p" style={{ paddingTop: 10, maxHeight: 100, textOverflow: 'ellipsis' }}>
          {request.summary}
        </Text>
      </div>
      <Text as="ParagraphSmall" tag="p" style={styles.date}>
        {request.created_at.split('T')[0]}
      </Text>
      {request.status === 'pending' ? (
          <Button>Continue</Button>
      ) : null}
    </Card>
  )
}

const getStyles = tokens => ({
  card: {
    margin: '20px 0px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 300,
    overflow: 'hidden',
    flex: '1 0 auto',
    maxHeight: 400,
  },
  blue: {
    height: 90,
    marginBottom: 75,
    backgroundColor: tokens.Color.Brand300,
    width: '100%',
  },
  imageContainer: {
    position: 'absolute',
    top: 15,
    borderRadius: '50%',
    width: 150,
    height: 150,
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  container: {
    padding: 10,
  },
  date: {
    position: 'absolute',
    bottom: 0,
    right: 5,
  }
})