export const ActionTypes = {
  MY_ASKS_LOADING: 'MY_ASKS_LOADING',
  MY_ASKS_LOADED: 'MY_ASKS_LOADED',
}

export const myAsksLoaded = (payload) => ({
  type: ActionTypes.MY_ASKS_LOADED,
  payload,
})

export const myASksLoading = () => {
  type: ActionTypes.MY_ASKS_LOADING
}

