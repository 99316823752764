import React, { useCallback, useState, useReducer, useEffect } from 'react'
import { connect } from 'react-redux'
import useFetch from 'use-http'
import _find from 'lodash/find'
import _groupBy from 'lodash/groupBy'
import { useParams } from 'react-router-dom'

import { Button } from '@kyper/button'

import { productsLoaded } from '../../actions/Products'
import { askLoaded, resetAsk } from '../../actions/NewAskView'

import Story from './Story'
import Location from './Location'
import Demographics from './Demographics'
import StepProgress from './StepProgress'
import ShoppingCart from './ShoppingCart'
import ConfirmItems from './ConfirmItems'
import Finished from './Finished'

import { checkForMobile } from '../../utils/browser'
import { ASK_TIME_FRAME, checkAskDates } from '../../utils/asks'

const NewRequest = ({ askLoaded, myAsks, productsLoaded, resetAsk }) => {
  const isMobile = checkForMobile()
  const [step, setStep] = useState(1)
  const [request, setRequest] = useState({})
  let { id } = useParams();
  const styles = getStyles()

  const onNext = () => {
    setStep(step + 1)
  }
  
  const {
    get:getProducts,
    response:productResponse,
  } = useFetch()

  useEffect(() => {
    if (id) {
      const ask = _find(myAsks, { id: parseInt(id) }) || {}

      delete ask.user

      askLoaded(ask)
    }
  }, [id])

  useEffect(() => {
    loadProducts()

    return () => {
      resetAsk()
    }
  }, []) // componentDidMount

  async function loadProducts() {
    const initialProducts = await getProducts('/api/v1/products')

    if (productResponse.ok) {
      productsLoaded(initialProducts)
    }
  }

  return (
    <div style={styles.container}>
      {step !== 6 ? (
        <StepProgress step={step} />
      ) : null}

      {step === 1 ? (
        <Location onNext={onNext} />
      ) : null}

      {step === 2 ? (
        <Demographics onNext={onNext} />
      ): null}

      {step === 3 ? (
        <Story onNext={onNext} />
      ) : null}
      {step === 4 ? (
        <ShoppingCart onNext={onNext} />
      ) : null}
      {step === 5 ? (
        <ConfirmItems onNext={onNext} />
      ) : null}
      {step === 6 ? (
        <Finished />
      ) : null}
    </div>
  )
}

const getStyles = () => ({
  container: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    marginTop: 10,
  }
})

const mapDispatchToProps = dispatch => ({
  productsLoaded: payload => dispatch(productsLoaded(payload)),
  askLoaded: payload => dispatch(askLoaded(payload)),
  resetAsk: () => dispatch(resetAsk()),
})

const mapStateToProps = state => ({
  myAsks: state.myAsks.items
})

export default connect(mapStateToProps, mapDispatchToProps)(NewRequest)