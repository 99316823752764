import React from 'react'

import { ProgressBar, Step } from 'react-step-progress-bar'
import "react-step-progress-bar/styles.css";

import { useTokens } from '@kyper/tokenprovider'

const getStyles = tokens => ({
  step: {
    borderRadius: '50%',
    height: 40,
    width: 40,
    backgroundColor: tokens.Color.Neutral500,
    fontSize: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFF',
  },
  accomplishedStep: {
    backgroundColor: tokens.Color.Primary300,
  },
})

const stepToPercent = step => {
  switch (step) {
    case 1:
      return 0
    case 2:
      return 25
    case 3:
      return 50
    case 4:
      return 75
    case 5:
      return 100
    default:
      return 0
  }
}
const StepProgress = ({ step }) => {
  const tokens = useTokens()
  const styles = getStyles(tokens)
  const percent = stepToPercent(step)

  return (
    <div style={{ marginBottom: 40, padding: '0px 20px' }}>
      <ProgressBar percent={percent}>
        <Step>
          {({ accomplished, index }) => (
            <div style={{...styles.step, ...(accomplished ? styles.accomplishedStep : {}) }}>
              {index + 1}
            </div>
          )}
        </Step>
        <Step>
          {({ accomplished, index }) => (
            <div style={{...styles.step, ...(accomplished ? styles.accomplishedStep : {}) }}>
              {index + 1}
            </div>
          )}
        </Step>
        <Step>
          {({ accomplished, index }) => (
            <div style={{...styles.step, ...(accomplished ? styles.accomplishedStep : {}) }}>
              {index + 1}
            </div>
          )}
        </Step>
        <Step>
          {({ accomplished, index }) => (
            <div style={{...styles.step, ...(accomplished ? styles.accomplishedStep : {}) }}>
              {index + 1}
            </div>
          )}
        </Step>
        <Step>
          {({ accomplished, index }) => (
            <div style={{...styles.step, ...(accomplished ? styles.accomplishedStep : {}) }}>
              {index + 1}
            </div>
          )}
        </Step>
      </ProgressBar>
    </div>
  )
}

export default StepProgress
