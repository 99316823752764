import _each from 'lodash/each'
import _hasKey from 'lodash/has'
import _includes from 'lodash/includes'
import _isEmpty from 'lodash/isEmpty'
import _isFunction from 'lodash/isFunction'
import _isNil from 'lodash/isNil'
import _isFinite from 'lodash/isFinite'
import _isString from 'lodash/isString'
import _keys from 'lodash/keys'
import _trim from 'lodash/trim'

import _pickBy from 'lodash/pickBy'

// Temporary until we convert this over to new translations.
function template(strings, ...keys) {
  return function(...values) {
    const dict = values[values.length - 1] || {}
    const result = [strings[0]]

    keys.forEach(function(key, i) {
      const value = Number.isInteger(key) ? values[key] : dict[key]

      result.push(value, strings[i + 1])
    })
    return result.join('')
  }
}

const messages = {
  VALIDATION_REQUIRED: template`${'value'} is required`,
  VALIDATION_MIN: template`${'value'} must be greater than or equal to ${'expectation'}`,
  VALIDATION_MAX: template`${'value'} must be less than or equal to ${'expectation'}`,
  VALIDATION_RANGE: template`${'value'} must be between ${'lower'} and ${'upper'}`,
  VALIDATION_LENGTH: template`${'value'} must be ${'length'} characters`,
  VALIDATION_MINLENGTH: template`${'value'} must be at least ${'length'} characters`,
  VALIDATION_MAXLENGTH: template`${'value'} must be at most ${'length'} characters`,
  VALIDATION_RANGELENGTH: template`${'value'} must be between ${'lower'} and ${'upper'} characters`,
  VALIDATION_ONEOF: template`${'value'} must be one of: ${'list'}`,
  VALIDATION_NUMBER: template`${'value'} must be a number`,
  VALIDATION_DIGITS: template`${'value'} must only contain digits`,
  VALIDATION_EMAIL: template`${'value'} must be a valid email`,
  VALIDATION_URL: template`${'value'} must be a valid url`,
  VALIDATION_INLINEPATTERN: template`${'value'} is invalid`,
  VALIDATION_EQUALTO: template`${'value'} must be the same as ${'expectation'}`,
}

const patternMessages = {
  number: messages.VALIDATION_NUMBER,
  digits: messages.VALIDATION_DIGITS,
  email: messages.VALIDATION_EMAIL,
  url: messages.VALIDATION_URL,
}

export const Validation = {
  DEFAULT_PATTERNS: {
    digits: /^\d+$/,
    number: /^-?\.?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/,
    money: /^-?\.?(?:(0|[1-9][0-9]*)|\d{1,3}(?:,\d{3})+)(?:\.\d{2})?$/,
    email: /^\S+@\S+\.\S+$/,
  },

  _isInt(n) {
    return n % 1 === 0
  },

  _isFloat(n) {
    return Number(n) === n && n % 1 !== 0
  },

  _defaultValidators: {
    required(key, value, isRequired, schema) {
      if (!this._hasValue(value) && !isRequired) {
        return false
      }
      if (!this._hasValue(value) && isRequired) {
        return messages.VALIDATION_REQUIRED({ value: this._formatLabel(key, schema) })
      }
      return ''
    },

    min(key, value, minValue, schema) {
      const errorMessage = messages.VALIDATION_MIN({
        value: this._formatLabel(key, schema),
        expectation: minValue,
      })

      if (!this._hasValue(value) || !value.toString().match(this.DEFAULT_PATTERNS.number)) {
        return errorMessage
      }

      const newValue = Number(value)

      if (this._isInt(newValue) && parseInt(value, 0) < minValue) {
        return errorMessage
      }

      if (this._isFloat(newValue) && parseFloat(value) < minValue) {
        return errorMessage
      }
      return ''
    },

    max(key, value, maxValue, schema) {
      const parsedValue = parseFloat(value)

      if (!_isFinite(parsedValue) || parsedValue > maxValue) {
        return messages.VALIDATION_MAX({
          value: this._formatLabel(key, schema),
          expectation: maxValue,
        })
      }
      return ''
    },

    range(key, value, range, schema) {
      const parsedValue = parseFloat(value)

      if (!_isFinite(parsedValue) || parsedValue < range[0] || parsedValue > range[1]) {
        return messages.VALIDATION_RANGE({
          value: this._formatLabel(key, schema),
          lower: range[0],
          upper: range[1],
        })
      }
      return ''
    },

    length(key, value, length, schema) {
      if (!_isString(value) || value.length !== length) {
        return messages.VALIDATION_LENGTH({
          value: this._formatLabel(key, schema),
          length,
        })
      }
      return ''
    },

    minLength(key, value, minLength, schema) {
      if (!_isString(value) || value.length < minLength) {
        return messages.VALIDATION_MINLENGTH({
          value: this._formatLabel(key, schema),
          length: minLength,
        })
      }
      return ''
    },

    maxLength(key, value, maxLength, schema) {
      if (!_isString(value) || value.length > maxLength) {
        return messages.VALIDATION_MAXLENGTH({
          value: this._formatLabel(key, schema),
          length: maxLength,
        })
      }
      return ''
    },

    rangeLength(key, value, range, schema) {
      if (!_isString(value) || value.length < range[0] || value.length > range[1]) {
        return messages.VALIDATION_RANGELENGTH({
          value: this._formatLabel(key, schema),
          lower: range[0],
          upper: range[1],
        })
      }
      return ''
    },

    oneOf(key, value, values, schema) {
      if (!_includes(values, value)) {
        return messages.VALIDATION_ONEOF({
          value: this._formatLabel(key, schema),
          list: values.join(', '),
        })
      }
      return ''
    },

    pattern(key, value, pattern, schema) {
      if (
        !this._hasValue(value) ||
        !value.toString().match(this.DEFAULT_PATTERNS[pattern] || pattern)
      ) {
        const temp = patternMessages[pattern]
          ? patternMessages[pattern]
          : messages.VALIDATION_INLINEPATTERN

        return temp({ value: this._formatLabel(key, schema) })
      }
      return ''
    },

    equalTo(key, value, attribute, schema, data) {
      if (value !== data[attribute]) {
        return messages.VALIDATION_EQUALTO({
          value: this._formatLabel(key, schema),
          expectation: this._formatLabel(attribute, schema),
        })
      }
      return ''
    },
  },

  _formatLabel(key, schema) {
    return schema[key].label || key
  },

  _getValidatorsFromSchema(schema, key) {
    const defaultValidatorKeys = _keys(this._defaultValidators)

    return _keys(schema[key]).filter(value => {
      return defaultValidatorKeys.indexOf(value) !== -1
    })
  },

  _hasValue(value) {
    return (
      _isFinite(value) ||
      !(_isNil(value) || _isEmpty(value) || (_isString(value) && _trim(value) === ''))
    )
  },

  _validateKey(schema, key, value, data) {
    let errorMessage = ''

    if (_hasKey(schema, key)) {
      if (_isFunction(schema[key])) {
        errorMessage = schema[key](value)
      } else {
        const validators = this._getValidatorsFromSchema(schema, key)

        errorMessage = validators.reduce((message, validator) => {
          const result = this._defaultValidators[validator].call(
            this,
            key,
            value,
            schema[key][validator],
            schema,
            data,
          )

          if ((!value && result === false) || message === false) {
            return false
          }

          if (result && !message) {
            return result
          }

          return message
        }, '')
      }
    }

    return errorMessage
  },

  /**
   * Validate `data` against a `schema`.
   *
   * WARNING: only keys present in `data` are validated, even if they're required in the `schema`.
   */
  validate(schema, data) {
    const invalidKeys = {}

    _each(data, (val, key) => {
      const error = this._validateKey(schema, key, val, data)

      if (error) {
        invalidKeys[key] = error
      }
    })

    return _isEmpty(invalidKeys) ? null : invalidKeys
  },

  /**
   * This helps to manage validating an array of fields.
   * currentErrors are combined with any new errors and resolved errors are removed.
   *
   * @param {Object} schema - schema for validation
   * @param {Object[]} fieldNames - an array of all the field names to be validated
   * @param {Object} data - the object to validate against the schema
   * @param {Object} currentErrors - errors from previously running this function
   * @returns {Object} validation errors
   */
  validateFields(schema, fieldNames, data, currentErrors) {
    const newErrors = fieldNames.reduce((errors, field) => {
      const error =
        Validation.validate(schema, {
          // ensure there is a key so the field is validated
          [field]: null,
          ...data,
        }) || {}

      return {
        ...errors,
        [field]: error[field] || null,
      }
    }, {})

    return _pickBy({ ...currentErrors, ...newErrors })
  },
}

export default Validation
