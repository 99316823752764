import React from "react"
import { Provider } from 'react-redux'
import PropTypes from "prop-types"
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"

import { TokenProvider, TokenContext } from '@kyper/tokenprovider'
import { Text } from '@kyper/text'

import Login from './Login'
import Header from './Header'
import Nav from './Nav'
import AskHome from './AskHome'
import NewAsk from './Requests/NewRequest'
import Home from './Home'
import FAQ from './Faq'

import { Terms } from './TermsAndConditions'
import { Privacy } from './Privacy'

import { checkForMobile } from '../utils/browser'

import configureStore from '../configureStore'

const store = configureStore()

class Root extends React.Component {

  static contextType = TokenContext

  constructor(props) {
    super(props)
    this.state = {
      // default to open for desktop
      isNavOpen: !checkForMobile(),
      currentUser: window.app.current_user
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const isMobile = checkForMobile()

    if (isMobile) {
      if (this.state.isNavOpen) {
        document.querySelector('.root-container').style = 'overflow:hidden'
      }

      if (prevState.isNavOpen && !this.state.isNavOpen) {
        document.querySelector('.root-container').style = ''
      }
    }
  }

  _toggleNav = () => {
    this.setState({ isNavOpen: !this.state.isNavOpen })
  }

  render () {
    const { isNavOpen, currentUser } = this.state
    const shouldShowNav = isNavOpen && currentUser
    const isMobile = checkForMobile()
    const styles = this.styles(shouldShowNav, isMobile)

    return (
      <Provider store={store}>
        <Router>
          <TokenProvider>
              {!currentUser ? (
                <div style={{ padding: isMobile ? 20 : 40, height: '100%'}}>
                  <Switch>
                    <Route path="/" exact={true}>
                      <Login />
                    </Route>
                    <Route path="/privacy">
                      <Privacy />
                    </Route>
                    <Route path="/terms">
                      <Terms />
                    </Route>
                  </Switch>
                </div>
              ) : (
                <App
                  currentUser={currentUser}
                  isNavOpen={isNavOpen}
                  onToggleNav={this._toggleNav}
                  styles={styles}
                />
              )}
          </TokenProvider>
        </Router>
      </Provider>
    );
  }

  styles = (isNavOpen, isMobile) => {
    const tokens = this.context

    // handle menu open close and mobile
    let menuPosition = 0
    let bodyMargin = 220
    let bodyPosition = 0

    if (isMobile ) {
      bodyMargin = 0

      if (isNavOpen) {
        bodyPosition = 220
      } else {
        menuPosition = -220
      }
    }

    return {
      left: {
        position: 'fixed',
        left: menuPosition,
        width: 220,
        zIndex: 100,
        transition: 'all 0.2s ease-in-out',
        height: '100%',
      },
      right: {
        position: 'relative',
        marginLeft: bodyMargin,
        left: bodyPosition,
        backgroundColor: tokens.BackgroundColor.Body,
        height: '100%',
        overflow: isNavOpen && isMobile ? 'hidden' : 'auto',
        boxShadow: '-3px 0 6px #556270',
        zIndex: 101,
        padding: isMobile ? 20 : 40,
        transition: 'all 0.2s ease-in-out',
      },
    }
  }
}

const App = ({ isNavOpen, onToggleNav, currentUser, styles }) => {
  return (
    <React.Fragment>
      <div style={styles.left}>
        <Nav currentUser={currentUser} onNavigationClick={onToggleNav} open={isNavOpen} />
      </div>
      <div style={styles.right}>
        <Header onMenuToggle={onToggleNav}/>
        <Switch>
          <Route exact={true} path="/requests">
            <AskHome />
          </Route>
          <Route path="/requests/new">
            <NewAsk />
          </Route>
          <Route path="/requests/edit/:id">
            <NewAsk />
          </Route>
          <Route path="/give">
            <div>
              <Text as="H1" tag="h1" style={{ marginBottom: 30 }}>COMING SOON</Text> 
              <Text as="Paragraph" tag="p">Let us handle buying and delivering the items for a specific Ask.</Text>
            </div>
          </Route>
          <Route path="/settings">
            <div>Settings</div>
          </Route>
          <Route path="/faq">
            <FAQ />
          </Route>
          <Route exact={true} path="/">
            <Home />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
        </Switch>
      </div>
    </React.Fragment>
  )
}


export default Root
