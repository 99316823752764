// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from 'react'
import ReactDOM from 'react-dom'

import App from '../components/App'


import Honeybadger from 'honeybadger-js'
import ErrorBoundary from '@honeybadger-io/react'

Honeybadger.configure({
  apiKey: 'ae584de8',
  environment: 'production'
})

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <ErrorBoundary honeybadger={Honeybadger}>
      <App />
    </ErrorBoundary>,
    document.getElementById('root-container'),
  )
})
