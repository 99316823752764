import React from 'react'
import { Link } from "react-router-dom"
import useFetch from 'use-http'

import { Card } from '@kyper/card'
import { Text } from '@kyper/text'

import { checkForMobile } from '../utils/browser'


// We allow links in the FAQ.
function createMarkup(summary) {
  return {__html: summary};
}

const FAQ = () => {
  const isMobile = checkForMobile()
  const { loading, error, data = [] } = useFetch('/api/v1/faqs', {}, [])

  return (
    <Card padding={isMobile ? "small" : "large"}>
      {loading ? (<div>Loading</div>) : (
        <div>
          <Text as="H1" tag="h1">Frequently Asked Questions</Text>
          <br />
          <br />

          {data.map(q => {
            return (
              <React.Fragment key={q.id}>
                <Text as="H2" tag="h2">{q.title}</Text>
                <p dangerouslySetInnerHTML={createMarkup(q.summary)} />
                <br />
              </React.Fragment>
            )
          })}
        </div>
      )}
    </Card>
  )
}

export default FAQ