import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import { Text } from '@kyper/text'

import logo from '../images/IH_Logo_Wide.png'
import fblogin from '../images/fblogin.png'

class Login extends React.Component {
  render () {
    const styles = this.styles()

    return (
      <div style={styles.container}>
        <img alt="Intellihelp Logo" src={logo} style={styles.logo} />
        <a href="/login" style={styles.login}>
          <img alt="Login with Facebook" src={fblogin} style={styles.fb} />
        </a>
        <br />
        <br />
        <Text as="ParagraphSmall" tag="p">
          *By using this application, you're agreeing to our <Link to='/terms'>Terms and Conditions</Link>
        </Text>
      </div>
    )
  }

  styles = () => {
    return {
      container: {
        boxSizing: 'border-box',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
      },
      login: {
        width: '100%',
        textAlign: 'center',
      },
      logo: {
        width: '100%',
        maxWidth: 320,
        paddingBottom: 20,
      },
      fb: {
        width: '100%',
        maxWidth: 320
      }
    }
  }
}

export default Login
