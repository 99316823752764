import moment from 'moment'

export const ASK_TIME_FRAME = {
  'PENDING': 'PENDING',
  'OPEN': 'OPEN',
  'FOURTEENDAYS': 'FOURTEENDAYS',
  'SIXMONTHS': 'SIXMONTHS',
  'VALID': 'VALID'
}

export const ASK_TIME_MESSAGE = {
  PENDING: 'You have a unfinished request. Please edit to continue.',
  OPEN: 'You already have an open request. This will automatically close after X days.',
  FOURTEENDAYS: 'You can only submit 1 ask within a 14 day period.',
  SIXMONTHS: 'You can only submit a maximum of 4 asks in any 6 month period.',
}

export const checkAskDates = data => {
  // No two posts within a 14 day time period
  // No more than 4 posts within a 6 month time period
  // return ASK_TIME_FRAME.VALID

  if (!data.length) {
    return ASK_TIME_FRAME.VALID
  }

  const pendingRequest = data.filter(item => item.status === "pending")

  if (pendingRequest.length) {
    return ASK_TIME_FRAME.PENDING
    // return "You have an ask you didn't complete."
  }

  // if (data.filter(request => request.status === 'open').length) {
  //   return ASK_TIME_FRAME.OPEN
  //   // return 'You can only have 1 open ask at a time.'
  // }

  const fourteenDaysAgo = moment().startOf('day').subtract(14, 'days')
  const fourteenDays = data.filter(request => {
    return moment(request.created_at).startOf('day') > fourteenDaysAgo
  })

  if (fourteenDays.length > 0) {
    return ASK_TIME_FRAME.FOURTEENDAYS
    // return 'You can only submit two asks within a 14 day period.'
  }

  const sixMonthsAgo = moment().startOf('day').subtract(6, 'months')
  const sixMonths = data.filter(request => {
    return moment(request.created_at).startOf('day') > sixMonthsAgo 
  })

  if (sixMonths.length >= 4) {
    return ASK_TIME_FRAME.SIXMONTHS
    // return 'You can only submit a maximum of 4 asks every 6 months'
  }

  return ASK_TIME_FRAME.VALID
}
