import React from 'react'
import { Link } from "react-router-dom"

import { useTokens } from '@kyper/tokenprovider'
import { Button } from '@kyper/button'
import { Card } from '@kyper/card'
import { Dollar, Heart } from '@kyper/icon'
import { Text } from '@kyper/text'

import header from '../images/home_header.png'
import { checkForMobile } from '../utils/browser'


const Home = () => {
  const tokens = useTokens()
  const isMobile = checkForMobile()
  const styles = getStyles(tokens, isMobile)

  return (
    <Card padding={isMobile ? "small" : "large"}>
      <div style={styles.imageContainer}>
        <img alt="People helped" src={header} style={styles.image} />
      </div>
      <div style={styles.container}>
        <div style={styles.card}>
          <Text as="H1" tag="h1" style={styles.text}>
            ASK
          </Text>
          <hr style={{ width: '100%'}} />
          <Text as="H2" tag="p" style={styles.text}>
            I AM IN NEED OF 
          </Text>
          <Text as="H2" tag="p" style={styles.text}>
            SOME BASIC 
          </Text>
          <Text as="H2" tag="p" style={styles.text}>
            ESSENTIALS
          </Text>
          <Link to='/requests' style={{ textDecoration: 'none' }}>
            <Button style={styles.button}>
              <Heart color={tokens.Color.Brand300} style={styles.icon} />
              Ask
            </Button>
          </Link>
        </div>

        <div style={{...styles.card, ...styles.give}}>
          <Text as="H1" tag="h1" style={styles.text}>
            GIVE
          </Text>
          <hr style={{ width: '100%'}} />
          <Text as="H2" tag="p" style={styles.text}>
            I WANT TO FULFILL
          </Text>
          <Text as="H2" tag="p" style={styles.text}>
            A NEED RIGHT
          </Text>
          <Text as="H2" tag="p" style={styles.text}>
            NOW
          </Text>
          <Link to='/give' style={{ textDecoration: 'none' }}>
            <Button style={styles.button}>
              <Dollar color={tokens.Color.Brand300} style={styles.icon} />
              Give
            </Button>
          </Link>
        </div>
      </div>
    </Card>
  )
}

const getStyles = (tokens, isMobile) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: isMobile ? 'column' : 'row',
    flexWrap: 'wrap',
  },
  text: {
    color: 'white',
  },
  card: {
    color: 'white',
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: tokens.Color.Brand300,
    minWidth: 300,
    maxWidth: '100%',
    borderRadius: 5,
    margin: isMobile ? '20px 0px' : 20,
  },
  give: {
    backgroundColor: tokens.Color.Error300
  },
  button: {
    marginTop: 20,
  },
  image: {
    maxWidth: '100%',
  },
  imageContainer: {
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
  },
})

export default Home