import React, { useCallback, useEffect, useState } from 'react'
import useFetch from 'use-http'

import { Button } from '@kyper/button'
import { Card } from '@kyper/card'
import { Select } from '@kyper/select'
import { Text } from '@kyper/text'

import { checkForMobile } from '../../utils/browser'
import { Validation } from '../../utils/Validation'

const boolItems = [
  {label: 'Yes', value: 'true'},
  {label: 'No', value: 'false'}
]

const householdItems = [
  { label: 'Single Mom', value: 1 },
  { label: 'Single Dad', value: 2 },
  { label: 'Two-Parent Household', value: 3 },
  { label: 'Senior Individual', value: 4 },
  { label: 'Senior Couple', value: 5 },
  { label: 'Senior(s) with Grandkids in Home', value: 6 },
  { label: 'Single Woman Living Alone', value: 7 },
  { label: 'Single Man Living Alone', value: 8 },
  { label: 'College Student', value: 9 },
]

export const Demographics = ({ onNext }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [errors, setErrors] = useState({})
  const [demo, setDemo] = useState({ is_employed: '', has_stable_housing: '', household: '' })
  const styles = getStyles()
  const isMobile = checkForMobile()
  const user = window.app.current_user
  const url = `/api/v1/users/${user.id}/demographics`

  const { get, post, put, response, loading, error } = useFetch({ cachePolicy: 'no-cache', data: {} })

  const handleNext = useCallback(async () => {
    const check = Validation.validate(schema, demo) || {}
    setErrors(check)

    if (Object.keys(check).length) {
      return
    }
    
    if (demo.id) {
      const updateDemo = await put(`${url}/${demo.id}`, { is_employed: demo.is_employed, has_stable_housing: demo.has_stable_housing, household: demo.household })

      if (response.ok) {
        onNext()
      }
    } else {
      const newDemo = await post(url, { is_employed: demo.is_employed, has_stable_housing: demo.has_stable_housing, household: demo.household })

      if (response.ok) {
        onNext()
      }
    }
  })

  const loadDemographics = useCallback(async () => {
    const initialDemo = await get(url)

    if (response.ok) {
      if (initialDemo.id) {
        setDemo(initialDemo)
      }
      setIsLoading(false)
    }
  }, [get, response])

  useEffect(() => { loadDemographics() }, []) // componentDidMount

  return (
    <React.Fragment>
      <Card style={styles.card} padding={isMobile ? 'small' : 'large'}>
        {loading || isLoading ? (
          <Text>Loading</Text>
        ): (
          <React.Fragment>
            <div style={{...styles.row, marginBottom: 20 }}>
              <Text as="H2" tag="h2">
                General Information
              </Text>
              <Text as="Paragraph" tag="p">
                This will help us determine future giving intiatives.
              </Text>
            </div>
            <div style={styles.row}>
              <Text tag="p">
                Are you currently employed?
              </Text>
              <Select
                errorText={errors.is_employed}
                initialSelectedItem={boolItems.find(item => item.value === demo.is_employed.toString())}
                items={boolItems} 
                label="Employed"
                name="is_employed"
                onChange={item => {
                  setDemo({ ...demo, is_employed: item.value })
                }}
              />
            </div>

            <div style={styles.row}>
              <Text tag="p">
                Do you have stable housing?
              </Text>
              <Select
                errorText={errors.has_stable_housing}
                initialSelectedItem={boolItems.find(item => item.value === demo.has_stable_housing.toString())}
                items={boolItems} 
                label="Housing"
                name="has_stable_housing"
                onChange={item => {
                  setDemo({ ...demo, has_stable_housing: item.value })
                }}
              />
            </div>

            <div style={styles.row}>
              <Text tag="p">
                Select one that best represents your household: 
              </Text>
              <Select
                errorText={errors.household}
                initialSelectedItem={householdItems.find(item => item.value === demo.household)}
                items={householdItems} 
                label="Household"
                name="household"
                onChange={item => {
                  setDemo({ ...demo, household: item.value })
                }}
              />
            </div>
          </React.Fragment>
        )}
      </Card>
      <Button onClick={handleNext} style={styles.button} variant="primary">Next</Button>
    </React.Fragment>
  )
}

const schema = {
  is_employed: (val) => {
    if (!val.toString()) {
      return 'Employed information required'
    }

    return ''
  },
  has_stable_housing: (val) => {
    if (!val.toString()) {
      return 'Housing information required'
    }

    return ''
  },
  household: {
    label: 'Household information',
    required: true
  }
}

const getStyles = () => ({
  row: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 25,
  },
  button: {
    marginTop: 25,
  }
})

export default Demographics