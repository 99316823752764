import React, { useEffect } from 'react'
import useFetch from 'use-http'
import _find from 'lodash/find'
import { css } from 'glamor'
import { MessageBox } from '@kyper/messagebox'
import { Hamburger } from '@kyper/icon'
import { Button } from '@kyper/button'

import { checkForMobile } from '../../utils/browser'

const LogOutRow = ({ children, number }) => {
  const isMobile = checkForMobile()

  if (isMobile) {
    return (
      <li>{number}. Press the &nbsp;<Hamburger />&nbsp; button at the top of the screen, then "Logout" in the blue navigation on the left.</li>
    )
  }

  if (children) {
    return (<li>{number}. {children}</li>)
  }

  return (
    <li>{number}. Press the "Logout" button in the blue navigation on the left.</li>
  )

}

const MISSING_GROUP = 'missing_group'
const MISSING_EMAIL = 'missing_email'
const GROUP_DECLINED = 'group_declined'
const FACEBOOK_ERROR = 'facebook_error'

const permissionMessage = (permissions, loading) => {
  if (loading) {
    return null
  }

  if (permissions.error) {
    return FACEBOOK_ERROR
  }

  const group = _find(permissions.data, {"permission":"publish_to_groups"}) || {}

  if (group.status === 'declined') {
    return GROUP_DECLINED
  }

  if (group.status !== 'granted') {
    return MISSING_GROUP
  }

  const email = _find(permissions.data, {"permission":"email"}) || {}

  if (email.status !== 'granted') {
    return MISSING_EMAIL
  }

  return null
}

const checkGroup = (group) => {
  if (group && group.data && group.data.length) {
    return true
  }

  return false
}

const CheckPermissions = ({ onError }) => {
  const styles = getStyles();
  const { loading, error, data = {} } = useFetch('/api/v1/permissions', { cachePolicy: 'no-cache' }, [])
  const { loading: groupLoading, error: groupError, data: groupData = {} } = useFetch('/api/v1/check_group', { cachePolicy: 'no-cache' }, [])
  const message = permissionMessage(data, loading) 
  const hasGroup = checkGroup(groupData)
  const hasDeclinedGroup = message === GROUP_DECLINED

  useEffect(() => {
    if (!loading && !groupLoading) {
      if (!hasGroup || hasDeclinedGroup) {
        onError()
      }
    }
  }, [loading, groupLoading, hasGroup, hasDeclinedGroup])

  if (loading || groupLoading) return null

  if (!message && hasGroup) return null


  if (!hasGroup) {
    return (
      <MessageBox title="No Group Access" variant="error" style={{ marginBottom: 20 }}>
        The user you are logged in as doesn't appear to be a member of the Intellihelp USA group.
        <br />
        <br />
        <ol className={css(styles.ol)}>
          <li>1. Logout of Facebook in all tabs, browsers, and applications.</li>
          <LogOutRow number={2} />
          <li>3. Login to https://app.intellihelp.org with the correct account.</li>
        </ol>
      </MessageBox>
    )
  }

  if (message === GROUP_DECLINED) {
    return (
      <MessageBox title="Group Permission Error" variant="error" style={{ marginBottom: 20 }}>
        When installing the Intellihelp USA Application, the proper group posting permissions were not selected. Please take the following steps:

        <br />
        <br />
        <ol className={css(styles.ol)}>
          <LogOutRow number={1}/>
          <li>2. When logging back in, make sure to press OK when it says "Intellihelp USA would like to post content into groups on your behalf".</li>
        </ol>
      </MessageBox>
    )
  } else if (message === MISSING_GROUP) {
    return (
      <MessageBox title="Group Permission Error" variant="error" style={{ marginBottom: 20 }}>
        When installing the Intellihelp USA Application, the proper permissions were not selected. Please take the following steps:

        <br />
        <br />
        <ol className={css(styles.ol)}>
          <li>1. Open Facebook</li>
          <li>2. Go to Settings</li>
          <li>3. Go to Apps and Websites</li>
          <li>4. Uninstall Intellihelp USA</li>
          <LogOutRow number={5} />
        </ol>
      </MessageBox>
    )
  } else if (message === MISSING_EMAIL) {
    return (
      <MessageBox title="Email Permission Error" variant="error" style={{ marginBottom: 20 }}>
        When installing the Intellihelp USA Application, the proper permissions were not selected. Please take the following steps:

        <br />
        <br />
        <ol className={css(styles.ol)}>
          <li>1. Open Facebook</li>
          <li>2. Go to Settings</li>
          <li>3. Go to Apps and Websites</li>
          <li>4. Uninstall Intellihelp USA</li>
          <LogOutRow number={5} />
          <li>6. Log back in</li>
        </ol>
      </MessageBox>
    )
  } else if (message === FACEBOOK_ERROR) {
    return (
      <MessageBox title="Permission Error!" variant="error" style={{ marginBottom: 20 }}>
        There was an issue verifying your Facebook permissions. The application might not behave as expected. Please pass this message onto a Moderator: <br /> <br />

        "{data.error.message}"
      </MessageBox>
    )
  }
}

const getStyles = () => {
  return ({
    ol: {
      lineHeight: '16px', 
      '> li': {
        marginBottom: 5
      }
    }
  })
}

export default CheckPermissions