import React, { useContext, useEffect, useState } from 'react'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import { AccordionContext } from 'react-bootstrap'

import { useTokens } from '@kyper/tokenprovider'
import { Text } from '@kyper/text'
import { Add } from '@kyper/icon'

import { checkForMobile } from '../../utils/browser'

import AccordionRow from './AccordionRow'

export const AccordionHeader = ({ children, count, eventKey }) => {
  const tokens = useTokens()
  const styles = getHeaderStyles(tokens)
  const onClick = useAccordionToggle(eventKey)

  const currentEventKey = useContext(AccordionContext);
  const isActive = currentEventKey === eventKey

  return (
    <div
      role="button"
      style={styles}
      onClick={onClick}
    >
      <Text as="H3" tag="h3">
        {children}
      </Text>
      { isActive ? (
        <Text as="H3" tag="h3">
          Total: {count}
        </Text>
      ): (
        <Add size={24} />
      )}
    </div>
  )
}

const getHeaderStyles = tokens => ({
  backgroundColor: tokens.Color.Neutral300,
  padding: '16px 24px',
  cursor: 'pointer',
  borderBottom: `1px solid ${tokens.Color.Neutral400}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const AccordionBody = ({ totalCount, products }) => {
  const styles = getBodyStyles()
  const isMobile = checkForMobile()

  return (
    <ul style={styles.ul}>
      {products.map(product => {
        return product.active && (
          <AccordionRow
            key={product.id}
            product={product}
            totalCount={totalCount}
          />
        )
      })}
    </ul>
  )
}

const getBodyStyles = () => ({
  ul: {
    listStyle: 'none',
  },
})


