import React from "react"
import PropTypes from "prop-types"

import { Button } from '@kyper/button'
import { Hamburger } from '@kyper/icon'

import { checkForMobile } from '../utils/browser'

import logo from '../images/IH_Logo_Wide.png'

class Header extends React.Component {
  static defaultProps = {
    onMenuToggle: () => {}
  }

  render () {
    const { onMenuToggle } = this.props
    const styles = this.styles()
    const isMobile = checkForMobile()

    return (
      <div style={styles.container}>
        {isMobile ? (
          <Button onClick={onMenuToggle} style={styles.hamburger}>
            <Hamburger />
          </Button>
        ) : null}
        <div>
          <img src={logo} style={styles.logo} />
        </div>
      </div>
    );
  }

  styles = () => {
    return {
      container: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: 20,
      },
      logo: {
        width: 250,
        position: 'relative',
        left: -15
      },
      hamburger: {
        marginRight: 20,
      }
    }
  }
}

export default Header 
