import { connect } from 'react-redux'
import React, { useEffect, useState, useCallback } from 'react'
import _times from 'lodash/times'
import _find from 'lodash/find'
import useFetch from 'use-http'

import { useTokens } from '@kyper/tokenprovider'
import { Text } from '@kyper/text'
import { CheckBox } from '@kyper/input' 
import { HelpFilled } from '@kyper/icon'
import { Select } from '@kyper/select'

import { checkForMobile } from '../../utils/browser'
import { askItemAdded, askItemRemoved, askItemUpdated } from '../../actions/NewAskView'

const AccordionRow = ({ ask, askItems, askItemAdded, askItemRemoved, askItemUpdated, product, totalCount }) => {
  const [showDescription, setShowDescription] = useState(false)
  const tokens = useTokens()
  const item = _find(askItems, { product_id: product.id })
  const checked = !!item

  const styles = getRowStyles(tokens)
  const isMobile = checkForMobile()

  const showOptions = checked && (product.hard_limit > 1 || product.allow_sizes)
  let quantityOptions
  let initialQuantityOption
  let initialSizeOption

  if (product.hard_limit > 1) {
    quantityOptions = product.hard_limit > 1 && _times(product.hard_limit, index => ({
      label: (index + 1).toString(),
      value: (index + 1).toString(),
    }))

    if (item) {
      initialQuantityOption = _find(quantityOptions, {value: (item.quantity || 1).toString() }) || quantityOptions[0]
    }
  }
  
  const sizeOptions = product.allow_sizes && getSizeOptions(product.name)

  if (item) {
    // This will not catch people that have custom notes and size selection.
    initialSizeOption = _find(sizeOptions, { value: item.user_notes })
  }


  const baseURL = `/api/v1/requests/${ask.id}/request_items`

  const { post, put, del, response, loading } = useFetch({ cachePolicy: 'no-cache' })

  async function addItem() {
    if (loading) return null

    if (totalCount === 15) {
      alert('A maximum of 15 items can be selected')
      return null
    }

    const newItem = await post(baseURL, {
        request_id: ask.id,
        product_id: product.id,
        quantity: 1, 
      })

    if (response.ok) askItemAdded(newItem) 
  }

  async function removeItem() {
    if (loading) return null
    await del(`${baseURL}/${item.id}`)

    if (response.ok) askItemRemoved(item) 
  }

  async function updateItem(values) {
    if (loading) return null

    if (totalCount && values.quantity && (parseInt(values.quantity) + totalCount) > 15) {
      alert('A maximum of 15 items can be selected')
      return null
    }

    const updatedItem = await put(`${baseURL}/${item.id}`, {
      ...item,
      ...values,
    })

    if (response.ok) askItemUpdated(updatedItem)
  }

  return (
    <li style={styles.li}>
      <div style={styles.liHeader}>
        <span>
          <Text as="Paragraph" tag="p" style={{ display: 'flex', alignItems: 'center' }}>
            {product.name}
            {product.description ? (
              <button onClick={() => {
                setShowDescription(!showDescription)
              }} style={styles.infoButton}>
                <HelpFilled />
              </button>
            ) : null}
          </Text>
          {product.short_description ? (
            <Text as="ParagraphSmall" tag="span" color="secondary" style={{ paddingLeft: 2 }}>
              {product.short_description}
            </Text>
          ): null}
        </span>
        <CheckBox
          checked={checked}
          id={`checkbox-${product.id}`} 
          name={`checkbox-${product.id}`} 
          onChange={() => {
            if (checked) {
              removeItem()
            } else {
              addItem()
            }
          }}
          size={isMobile ? 24 : 16 }
        />
      </div>
      {showDescription ? (
        <Text as="ParagraphSmall" tag="span" color="secondary">
          {product.description}
        </Text>
      ): null}
      {showOptions ? (
        <div style={styles.optionContainer}>
          {product.hard_limit > 1 ? (
            <span style={{ width: 100 }}>
              <Select
                initialSelectedItem={initialQuantityOption}
                items={quantityOptions} 
                label="Quantity"
                onChange={item => {
                  updateItem({ quantity: item.value })
                }}
                showSecondaryLabelBackground={true}
              />
            </span>
          ): null}
          {product.allow_sizes ? (
            <span style={{ width: 200 }}>
              <Select
                initialSelectedItem={initialSizeOption}
                items={sizeOptions} 
                label="Size"
                onChange={item => {
                  updateItem({ user_notes: item.value })
                }}
                showSecondaryLabelBackground={true}
              />
            </span>
          ): null}
        </div>
      ): null}
    </li>
  )
}

const getRowStyles = tokens => ({
  li: {
    borderBottom: `1px solid ${tokens.Color.Neutral300}`,
    padding: 10,
  },
  liHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoButton: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    marginLeft: 5,
    display: 'flex',
    justifyContent: 'center',
  },
  optionContainer: {
    backgroundColor: tokens.Color.Neutral100,
    marginLeft: -10,
    marginRight: -10,
    marginTop: 10,
    padding: 20,
    display: 'flex',
    justifyContent: 'flex-end',
  } 
})

const getSizeOptions = name => {
  const n = name.toLowerCase()
  // this is so hacky haha.
  if (n.includes('diaper') && n.includes('adult')) {
    return [
      {label: 'Waist 8-26 inches', value: 'Waist 8-26 inches'},
      {label: 'Waist 24-32 inches', value: 'Waist 24-32 inches'},
      {label: 'Waist 32-44 inches', value: 'Waist 32-44 inches'},
      {label: 'Waist 45-58 inches', value: 'Waist 45-58 inches'},
      {label: 'Waist 56-64 inches', value: 'Waist 56-64 inches'},
    ]
  } else if (n.includes('diaper')) {
    return [
      {label: 'Newborn', value: 'Newborn'},
      {label: 'Size 1 (8-14 lbs)', value: 'Size 1'},
      {label: 'Size 2 (12-18 lbs)', value: 'Size 2'},
      {label: 'Size 3 (16-28 lbs)', value: 'Size 3'},
      {label: 'Size 4 (22-37 lbs)', value: 'Size 4'},
      {label: 'Size 5 (27+ lbs)', value: 'Size 5'},
      {label: 'Size 6 (35+ lbs)', value: 'Size 6'},
    ]
  } else if (n.includes('pullup')) {
    return [
      {label: 'Size 2T-3T', value: '2T-3T'},
      {label: 'Size 3T-4T', value: '3T-4T'},
      {label: 'Size 4T-5T', value: '4T-5T'},
    ]
  } else {
    return []
  }
}

const mapStateToProps = state => ({
  ask: state.newAskView.ask,
  askItems: state.newAskView.askItems,
})

const mapDispatchToProps = dispatch => ({
  askItemAdded: payload => dispatch(askItemAdded(payload)),
  askItemRemoved: payload => dispatch(askItemRemoved(payload)),
  askItemUpdated: payload => dispatch(askItemUpdated(payload)),
})

const ConnectedAccordionRow = connect(mapStateToProps, mapDispatchToProps)(AccordionRow)

export default ConnectedAccordionRow