import React from 'react'
import PropTypes from 'prop-types'
import { useTokens } from '@kyper/tokenprovider'
import { css } from 'glamor'

const ALIGN = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
}

export const Table = ({ align = 'left', component, children, rowCount, ...rest }) => {
  const Component = component ? component : 'table'
  const tokens = useTokens()
  const styles = {
    backgroundColor: tokens.BackgroundColor.Container,
    borderSpacing: 0,
    color: tokens.TextColor.Default,
    fontSize: tokens.FontSize.Small,
    width: '100%',

    // flex table
    '& .tr': {
      alignItems: 'center',
      display: 'flex',
    },

    '& .td, & .th': {
      display: 'flex',
    },

    '& .th': {
      flex: '1 1 auto',
      fontWeight: tokens.FontWeight.Bold,
    },

    '& .thead .tr': {
      alignItems: 'center',
    },

    '& .thead .tr .td': {
      textAlign: 'left',
    },

    '& .tbody .tr .td': {
      flex: '1 1 auto',
      textAlign: 'left',
    },

    // Header cells bottom border
    '& th, & .th': {
      borderBottom: `1px solid ${tokens.BorderColor.TableHeader}`,
      fontWeight: tokens.FontWeight.Bold,
    },
    // Body cells bottom border
    '& td, & .td': {
      borderBottom: `1px solid ${tokens.BorderColor.TableCell}`,
      fontWeight: tokens.FontWeight.Regular,
    },
    // Cell padding
    '& th, & td, & .td, & .th': {
      padding: tokens.Spacing.TableCell,
      textAlign: align,
      justifyContent: ALIGN[align],
    },
    // Body rows hover
    '& .tbody .tr:hover, & .tbody .tr:focus-within, & tbody tr:hover, & tbody tr:focus-within': {
      backgroundColor: tokens.BackgroundColor.TableRowHover,
    },

    '& tfoot tr td, & .tfoot .tr .td': {
      backgroundColor: tokens.BackgroundColor.TableFooter,
      padding: `${tokens.Spacing.TableFooterPaddingTop}px ${tokens.Spacing.TableFooterPaddingSide}px`,
      color: tokens.TextColor.Secondary,
      textAlign: align,
      justifyContent: ALIGN[align],
      width: '100%',
    },
  }

  return (
    <Component aria-rowcount={rowCount} className={css(styles)} role="table" {...rest}>
      {children}
    </Component>
  )
}

Table.propTypes = {
  align: PropTypes.oneOf(['center', 'left', 'right']),
  component: PropTypes.elementType,
  rowCount: PropTypes.number,
}