import React  from "react"
import PropTypes from "prop-types"

import { NavLink } from "react-router-dom"

import { useTokens } from '@kyper/tokenprovider'
import { Logout, Dollar, Heart, HelpOutline, Home, Settings } from '@kyper/icon'

import { checkForMobile } from '../utils/browser'

/*
        <li style={styles.li}>
          <NavLink activeStyle={styles.activeLink} onClick={handleNavigationClick} style={styles.link} to="/settings">
            <Settings color={tokens.Color.NeutralWhite} style={styles.icon} />
            Settings
          </NavLink>
        </li>
*/
const Nav = ({ currentUser, onNavigationClick }) => {
  const tokens = useTokens()
  const isMobile = checkForMobile()
  const styles = navStyles(tokens, isMobile)
  const handleNavigationClick = () => {
    if (isMobile) {
      onNavigationClick()
    }
  }

  return (
    <nav style={styles.nav}>
      <img alt="Profile picture" onClick={() => alert(currentUser.id)} src={currentUser.profile_pic} style={styles.profile} />
      <p>Welcome, {currentUser.first_name}</p>
      <ul style={styles.ul}>
        <li style={{...styles.li, ...styles.liFirst}}>
          <NavLink activeStyle={styles.activeLink} exact={true} onClick={handleNavigationClick} style={styles.link} to="/">
            <Home color={tokens.Color.NeutralWhite} style={styles.icon} />
            Home
          </NavLink>
        </li>
        <li style={styles.li}>
          <NavLink activeStyle={styles.activeLink} onClick={handleNavigationClick} style={styles.link} to="/requests">
            <Heart color={tokens.Color.NeutralWhite} style={styles.icon} />
            Ask
          </NavLink>
        </li>
        <li style={styles.li}>
          <NavLink activeStyle={styles.activeLink} onClick={handleNavigationClick} style={styles.link} to="/give">
            <Dollar color={tokens.Color.NeutralWhite} style={styles.icon} />
            Give
          </NavLink>
        </li>
        <li style={styles.li}>
          <NavLink activeStyle={styles.activeLink} onClick={handleNavigationClick} style={styles.link} to="/faq">
            <HelpOutline color={tokens.Color.NeutralWhite} style={styles.icon} />
            FAQ
          </NavLink>
        </li>
        <li style={styles.li}>
          <NavLink activeStyle={styles.activeLink} onClick={() => {
            window.location.replace('/logout')
          }} style={styles.link} to="/logout">
            <Logout color={tokens.Color.NeutralWhite} style={styles.icon} />
            Logout
          </NavLink>
        </li>
      </ul>
    </nav>
  )
}

const navStyles = (tokens, isMobile) => ({
  profile: {
    borderRadius: '50%',
    marginBottom: 5,
  },
  nav: {
    backgroundColor: tokens.Color.Primary300, 
    color: 'white',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: isMobile ? 20 : 40
  },
  ul: {
    width: '100%',
    display: 'block',
    margin: isMobile ? 20 : 40,
    padding: 0,
    listStyle: 'none',
    transition: 'all 300ms',
    MozTransition: 'all 300ms',
    WebkitTransition: 'all 300ms',
  },
  liFirst: {
    borderTop: `1px solid ${tokens.Color.Primary500}`
  },
  li: {
    borderBottom: `1px solid ${tokens.Color.Primary500}`
  },
  link: {
    padding:20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: tokens.Color.NeutralWhite,
    textDecoration: 'none',
  },
  icon: {
    marginRight: 10,
  },
  activeLink: {
    backgroundColor: tokens.Color.Primary500,
  }
})

export default Nav


