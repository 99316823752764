import React, { useRef } from 'react'

import { Card } from '@kyper/card'
import { useTokens } from '@kyper/tokenprovider'
import { Text } from '@kyper/text'

import { checkForMobile } from '../../utils/browser'

const Finished = () => {
  const isMobile = checkForMobile()
  const styles = getStyles()
  const ref = useRef()

  return (
    <Card padding={isMobile ? "small" : "large" }>
      <div ref={ref} style={styles.container}>
        <Text as="H2" tag="h2" style={styles.text}>
          Ask Submitted for Review!
        </Text>

        <div style={styles.iframecontainer}>
          <iframe width="100%" height={isMobile ? '250px' : '500px'} src="https://www.youtube.com/embed/o9MTpsbzgfM?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <Text as="Paragraph" tag="p" style={styles.text}>
          Community Rules: <a href="https://www.facebook.com/groups/Intellihelp/permalink/1162401184121606/">https://www.facebook.com/groups/Intellihelp/permalink/1162401184121606/</a>
        </Text>
      </div>
    </Card>
  )
}

const getStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  iframecontainer: {
    flex: '1 0 auto',
    marginBottom: 20,
  },
  text: {
    marginBottom: 20,
    wordWrap: 'break-word'
  }
})

export default Finished