import { ActionTypes } from '../actions/NewAskView'

export const defaultState = {
  ask: {},
  askItems: [],
  loading: false,
}

const askLoading = state => ({ ...state, loading: true })

const askLoaded = (state, action) => ({
  ...state,
  loading: false,
  ask: action.payload,
})

const askUpdated = (state, action) => ({
  ...state,
  ask: action.payload,
})

const askItemAdded = (state, action) => ({
  ...state,
  askItems: [...state.askItems, action.payload],
})

const askItemRemoved = (state, action) => ({
  ...state,
  askItems: state.askItems.filter(item => item.id !== action.payload.id)
})

const askItemUpdated = (state, action) => ({
  ...state,
  askItems: state.askItems.map(item => item.id === action.payload.id ? action.payload : item)
})

const askItemsLoaded = (state, action) => ({
  ...state,
  askItems: action.payload
})

const resetAsk = (state) => ({
  ...defaultState
})


export const newAskView = (state = defaultState, action) => {
  switch(action.type) {
    case ActionTypes.ASK_RESET:
      return resetAsk(state)
    case ActionTypes.ASK_UPDATED:
      return askUpdated(state, action)
    case  ActionTypes.ASK_LOADED:
      return askLoaded(state, action)
    case  ActionTypes.ASK_LOADING:
      return askLoading(state)
    case ActionTypes.ASK_ITEM_ADDED:
      return askItemAdded(state, action)
    case ActionTypes.ASK_ITEM_REMOVED:
      return askItemRemoved(state, action)
    case ActionTypes.ASK_ITEM_UPDATED:
      return askItemUpdated(state, action)
    case ActionTypes.ASK_ITEMS_LOADED:
      return askItemsLoaded(state, action)
    default:
      return state
  }
}
