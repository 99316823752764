import React, { useCallback, useEffect, useState } from 'react'
import useFetch from 'use-http'

import { Button } from '@kyper/button'
import { Card } from '@kyper/card'
import { Select } from '@kyper/select'
import { TextInput } from '@kyper/input'
import { Text } from '@kyper/text'

import { checkForMobile } from '../../utils/browser'
import { Validation } from '../../utils/Validation'

const url = '/api/v1/locations'

const Location = ({ onNext }) => {
  const styles = getStyles()
  const isMobile = checkForMobile()
  const [isLoading, setIsLoading] = useState(true)
  const [location, setLocation] = useState({ city: '', state: '', zipcode: '' })
  const [errors, setErrors] = useState({})
  const { get, post, put, response, loading, error } = useFetch({ data: [] })

  const loadLocation = useCallback(async () => {
    const initialLocation = await get(url)

    if (response.ok) {
      if (initialLocation.id) {
        setLocation(initialLocation)
      }

      setIsLoading(false)
    }
  }, [get, response])

  const handleNext = useCallback(async () => {
    const schema = {
      city: {
        label: 'City',
        required: true,
      },
      state: {
        label: 'State',
        required: true
      },
      zipcode: (val) => {
        if (!val) {
          return 'ZIP Code is required'
        }

        const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(val);

        if (!isValidZip) {
          return 'Must be a valid ZIP Code'
        }

        return ''
      },
    }

    const check = Validation.validate(schema, location) || {}
    setErrors(check)

    if (Object.keys(check).length) {
      return
    }
    
    if (location.id) {
      const updateLocation = await put(`${url}/${location.id}`, { city: location.city, state: location.state, zipcode: location.zipcode })

      if (response.ok) {
        onNext()
      }
    } else {
      const newLocation = await post(url, { city: location.city, state: location.state, zipcode: location.zipcode })

      if (response.ok) {
        onNext()
      }

    }
  }, [post, response, location])

  useEffect(() => { loadLocation() }, [loadLocation]) // componentDidMount

  return (
    <React.Fragment>
      <Card style={styles.card} padding={isMobile ? 'small' : 'large'}>
        {loading || isLoading ? (
          <Text>Loading</Text>
        ): (
          <React.Fragment>
            <div style={{...styles.row, marginBottom: 20 }}>
              <Text as="H2" tag="h2">
                Location Information
              </Text>
              <Text as="Paragraph" tag="p">
                Collecting your location information helps other givers looking to help out those in their own communities. 
              </Text>
            </div>
            <div style={styles.row}>
              <TextInput error={errors.city} label="City" name="city" onBlur={() => {}} onChange={e => setLocation({...location, city: e.target.value })} value={location.city} />
            </div>
            <div style={styles.row}>
              <Select
                errorText={errors.state}
                initialSelectedItem={states.find(item => item.value === location.state)}
                items={states} 
                label="State"
                name="state"
                onChange={item => setLocation({...location, state: item.value })}
              />
            </div>
            <div style={styles.row}>
              <TextInput error={errors.zipcode} label="ZIP Code" name="zipcode" value={location.zipcode} onChange={e => setLocation({...location, zipcode: e.target.value })}/>
            </div>
          </React.Fragment>
        )}
      </Card>
      <Button onClick={handleNext} style={styles.button} variant="primary">Next</Button>
    </React.Fragment>
  )
}

export default Location

const getStyles = () => ({
  row: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 25,
  },
  button: {
    marginTop: 25,
  }
})

export const states = [
  { value: 'Alabama', label: 'Alabama' },
  { value: 'Alaska', label: 'Alaska' },
  { value: 'American Samoa', label: 'American Samoa' },
  { value: 'Arizona', label: 'Arizona' },
  { value: 'Arkansas', label: 'Arkansas' },
  { value: 'California', label: 'California' },
  { value: 'Colorado', label: 'Colorado' },
  { value: 'Connecticut', label: 'Connecticut' },
  { value: 'Delaware', label: 'Delaware' },
  { value: 'District Of Columbia', label: 'District Of Columbia' },
  { value: 'Florida', label: 'Florida' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Guam', label: 'Guam' },
  { value: 'Hawaii', label: 'Hawaii' },
  { value: 'Idaho', label: 'Idaho' },
  { value: 'Illinois', label: 'Illinois' },
  { value: 'Indiana', label: 'Indiana' },
  { value: 'Iowa', label: 'Iowa' },
  { value: 'Kansas', label: 'Kansas' },
  { value: 'Kentucky', label: 'Kentucky' },
  { value: 'Louisiana', label: 'Louisiana' },
  { value: 'Maine', label: 'Maine' },
  { value: 'Maryland', label: 'Maryland' },
  { value: 'Massachusetts', label: 'Massachusetts' },
  { value: 'Michigan', label: 'Michigan' },
  { value: 'Minnesota', label: 'Minnesota' },
  { value: 'Mississippi', label: 'Mississippi' },
  { value: 'Missouri', label: 'Missouri' },
  { value: 'Montana', label: 'Montana' },
  { value: 'Nebraska', label: 'Nebraska' },
  { value: 'Nevada', label: 'Nevada' },
  { value: 'New Hampshire', label: 'New Hampshire' },
  { value: 'New Jersey', label: 'New Jersey' },
  { value: 'New Mexico', label: 'New Mexico' },
  { value: 'New York', label: 'New York' },
  { value: 'North Carolina', label: 'North Carolina' },
  { value: 'North Dakota', label: 'North Dakota' },
  { value: 'Ohio', label: 'Ohio' },
  { value: 'Oklahoma', label: 'Oklahoma' },
  { value: 'Oregon', label: 'Oregon' },
  { value: 'Pennsylvania', label: 'Pennsylvania' },
  { value: 'Puerto Rico', label: 'Puerto Rico' },
  { value: 'Rhode Island', label: 'Rhode Island' },
  { value: 'South Carolina', label: 'South Carolina' },
  { value: 'South Dakota', label: 'South Dakota' },
  { value: 'Tennessee', label: 'Tennessee' },
  { value: 'Texas', label: 'Texas' },
  { value: 'Utah', label: 'Utah' },
  { value: 'Vermont', label: 'Vermont' },
  { value: 'Virgin Islands', label: 'Virgin Islands' },
  { value: 'Virginia', label: 'Virginia' },
  { value: 'Washington', label: 'Washington' },
  { value: 'West Virginia', label: 'West Virginia' },
  { value: 'Wisconsin', label: 'Wisconsin' },
  { value: 'Wyoming', label: 'Wyoming' },
]
