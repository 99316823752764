export const ActionTypes = {
  ASK_LOADED: 'NewAskView/ASK_LOADED',
  ASK_RESET: 'NewAskView/ASK_RESET',
  ASK_UPDATED: 'ASK_UPDATED',
  ASK_ITEM_ADDED: 'ASK_ITEM_ADDED',
  ASK_ITEM_REMOVED: 'ASK_ITEM_REMOVED',
  ASK_ITEM_UPDATED: 'ASK_ITEM_UPDATED',
  ASK_ITEMS_LOADED: 'ASK_ITEMS_LOADED',
}

export const askLoaded = (payload) => ({
  type: ActionTypes.ASK_LOADED,
  payload,
})

export const updateAsk = (payload) => ({
  type: ActionTypes.ASK_UPDATED,
  payload
})

export const askItemAdded = (payload) => ({
  type: ActionTypes.ASK_ITEM_ADDED,
  payload,
})

export const askItemRemoved = (payload) => ({
  type: ActionTypes.ASK_ITEM_REMOVED,
  payload,
})

export const askItemUpdated = (payload) => ({
  type: ActionTypes.ASK_ITEM_UPDATED,
  payload,
})

export const askItemsLoaded = (payload) => ({
  type: ActionTypes.ASK_ITEMS_LOADED,
  payload,
})

export const resetAsk = () => ({
  type: ActionTypes.ASK_RESET
})

