import { connect } from 'react-redux'
import React, { useCallback, useEffect, useReducer, useState } from 'react'
import useFetch from 'use-http'
import Accordion from 'react-bootstrap/Accordion'
import { css } from 'glamor'

import { Button } from '@kyper/button'
import { Card } from '@kyper/card'
import { MessageBox } from '@kyper/messagebox'
import { Select } from '@kyper/select'
import { TextInput } from '@kyper/input'
import { Text } from '@kyper/text'

import { checkForMobile } from '../../utils/browser'
import { AccordionBody, AccordionHeader } from './Accordion'

import { askItemsLoaded } from '../../actions/NewAskView'

const productUrl = '/api/v1/products'

const PRODUCT_TYPE_MAP = {
  food: 'Food',
  house: 'Household Items',
  hygiene: 'Hygiene',
  medicine: 'Medicine',
  kids: 'Infant & Toddlers',
}

const ShoppingCart = ({ ask, askItems, askItemsLoaded, products, onNext }) => {
  const { get, response } = useFetch({ cachePolicy: 'no-cache' })

  useEffect(() => {
    loadAskItems()
  }, []) // componentDidMount

  async function loadAskItems() {
    const intialAskItems = await get(`/api/v1/requests/${ask.id}/request_items`)

    if (response.ok) {
      askItemsLoaded(intialAskItems)
    }
  }
  const styles = getStyles()
  const isMobile = checkForMobile()
  const totalCount = askItems.reduce((acc, curr) => {
    return acc + (parseInt(curr.quantity) || 1)
  }, 0)

  return (
    <React.Fragment>
      <Card style={styles.card} padding='none'>
        <div style={{...styles.row, padding: isMobile ? 16 :  24}}>
          <Text as="H2" tag="h2">
            Request Items
          </Text>
          <Text as="Paragraph" tag="p">
            You may select up to 15 items that you need.
          </Text>
        </div>
        <div className={css(styles.accordionContainer)} style={styles.row}>
          <Accordion>
            {Object.keys(products).map((productType, index) => {
              const name = PRODUCT_TYPE_MAP[productType]

              return(
                <div style={styles.aRow} key={name}>
                  <AccordionHeader count={totalCount} eventKey={index.toString()}>
                    {name}
                  </AccordionHeader>
                  <Accordion.Collapse eventKey={index.toString()}>
                    <AccordionBody
                      products={products[productType]}
                      totalCount={totalCount}
                    />
                  </Accordion.Collapse>
                </div>
              )
            })}
          </Accordion>
        </div>
      </Card>
{/*      {itemsError ? (
        <MessageBox title="Saving Error" variant="error">
          There was an error saving your data, please try again later.
        </MessageBox>
      ): null}
*/}      <Button disabled={askItems.length === 0} onClick={onNext} style={styles.button} variant="primary">Next</Button>
    </React.Fragment>
  )
}

const getStyles = () => ({
  row: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 25,
  },
  accordionContainer: {
    '& .collapse:not(.show)': {
      display: 'none'
    },
    '& .collapsing': {
      position: 'relative',
      height: 0,
      overflow: 'hidden',
      transition: 'height .35s ease'
    }
  },
  button: {
    marginTop: 25,
  }
})

const buildItemObject = (product) => ({
  product_id: product.id,
  user_notes: product.user_notes,
  quantity: product.quantity || 1,
})


const mapStateToProps = state => ({
  ask: state.newAskView.ask,
  askItems: state.newAskView.askItems,
  products: state.products.items
})

const mapDispatchToProps = dispatch => ({
  askItemsLoaded: payload => dispatch(askItemsLoaded(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart)
