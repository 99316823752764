import { applyMiddleware, combineReducers, createStore } from 'redux'

import { products } from './Products'
import { newAskView } from './NewAskView'
import { myAsks } from './MyAsks'

export const reducer = combineReducers({
  myAsks,
  newAskView,
  products,
})
