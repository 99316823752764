import { ActionTypes } from '../actions/MyAsks'

export const defaultState = {
  items: [],
  loading: false,
}

const myAsksLoading = state => ({ ...state, loading: true })

const myAsksLoaded = (state, action) => ({
  ...state,
  loading: false,
  items: action.payload,
})

export const myAsks = (state = defaultState, action) => {
  switch(action.type) {
    case  ActionTypes.MY_ASKS_LOADED:
      return myAsksLoaded(state, action)
    case  ActionTypes.MY_ASKS_LOADING:
      return myAsksLoading(state)
    default:
      return state
  }
}
