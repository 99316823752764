import { connect } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react'
import useFetch from 'use-http'
import { Link, useLocation } from "react-router-dom"

import { Button, ButtonGroup } from '@kyper/button'
import { Card } from '@kyper/card'
import { CheckBox } from '@kyper/input'
import { MessageBox } from '@kyper/messagebox'
import { Select } from '@kyper/select'
import { TextArea } from '../shared/TextArea'
import { Text } from '@kyper/text'
import { useTokens } from '@kyper/tokenprovider'

import { checkForMobile } from '../../utils/browser'
import { Validation } from '../../utils/Validation'
import { TermsAndConditions } from '../TermsAndConditions'

import { askItemUpdated } from '../../actions/NewAskView'

const selectItems = [
  { label: 'Pickup Or Delivery', value: 'any'},
  { label: 'Pickup Only', value: 'pickup'},
  { label: 'Delivery Only', value: 'delivery'}
]

const ConfirmItems = ({ onNext, ask, askItems, askItemUpdated }) => {
  const tokens = useTokens()
  const styles = getStyles(tokens)
  const isMobile = checkForMobile()
  const [itemsEditing, setItemsEditing] = useState([])
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [shouldShowTerms, setShowTerms] = useState(false)
  const [receivingOption, setReceivingOption] = useState('any')

  const location = useLocation()

  const { get, post, put, response, loading, error } = useFetch({cachePolicy: 'no-cache'})

  
  const handleNext = useCallback(async () => {
    await post(`/api/v1/requests/${ask.id}/save`)

    if (response.ok) {
      onNext()
    }
  }, [post, response])

  const handleReceivingChange = useCallback(async (choice) => {
    await put(`/api/v1/requests/${ask.id}`, { receiving_method: choice })
  })

  return (
    <React.Fragment>
      <Card style={styles.card} padding={isMobile ? 'small' : 'large'}>
        {loading ? (
          <Text>Loading</Text>
        ): (
          <React.Fragment>
            <div style={{...styles.row, marginBottom: 20 }}>
              <Text as="H2" tag="h2">
                Preferences & Confirmation
              </Text>
              <Text as="Paragraph" tag="p">
                The notes may be used to describe any allergies or restrictions only.  Asks with unauthorized notes will be denied.
              </Text>
            </div>
            <div style={styles.row}>
              <ul style={{ listStyle: 'none'}}>
                {askItems.map(item => (
                  <ItemRow askItemUpdated={askItemUpdated} item={item} itemsEditing={itemsEditing} setItemsEditing={setItemsEditing} key={item.id} />
                ))}
              </ul>
            </div>
          </React.Fragment>
        )}
      </Card>
      {itemsEditing.length ? (
        <MessageBox style={{ marginTop: 20 }} title="Unsaved Changes" variant="error">
          You have unsaved changes in your notes. Are you sure you want to continue without saving?
        </MessageBox>
      ) : null}
      {error ? (
        <MessageBox style={{ marginTop: 20 }} title="Error" variant="error">
          There was an error submitting your data to Facebook. Please try again later.
        </MessageBox>
      ) : null}
        <div style={{ padding: '20px 0px' }}>
          <Select
            initialSelectedItem={selectItems.find(item => item.value === 'any')}
            items={selectItems} 
            label="Receiving Method"
            name="receiving"
            onChange={item => handleReceivingChange(item.value)}
            showSecondaryLabelBackground={true}
          />
        </div>
        <div style={{ padding: '20px 0px' }}>
          <CheckBox
            id="terms-checkbox" 
            label={<span>I agree to the <a href="#" onClick={e => { e.preventDefault(); setShowTerms(true) }}>Terms and Conditions.</a></span>}
            labelPosition='right'
            name="terms-checkbox" 
            onChange={() => {
              setAcceptedTerms(!acceptedTerms)
            }}
            size={isMobile ? 24 : 16 }
          />
        </div>
      <Button disabled={loading || !acceptedTerms ? true : false} onClick={handleNext} style={styles.button} variant="primary">Submit Request</Button>
      {shouldShowTerms ? (
        <TermsAndConditions onClose={() => setShowTerms(false)} />
      ) : null}
    </React.Fragment>
  )
}

const getStyles = tokens => ({
  row: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 25,
  },
  button: {
  },

})

const mapStateToProps = state => ({
  ask: state.newAskView.ask,
  askItems: state.newAskView.askItems
})

const mapDispatchToProps = dispatch => ({
  askItemUpdated: payload => dispatch(askItemUpdated(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmItems)

const ItemRow = ({ askItemUpdated, item, itemsEditing, setItemsEditing }) => {
  const tokens = useTokens()
  const styles = getItemStyles(tokens)
  const [editNotes, setEditNotes] = useState(false)
  const [notes, setNotes] = useState(item.user_notes)
  const [errors, setErrors] = useState({})

  const { put, response, loading, error } = useFetch({ cachePolicy: 'no-cache' })

  const handleSave = useCallback(async () => {
    const schema = {
      notes: {
        label: 'Notes',
        maxLength: 50,
      }
    }
    const check = Validation.validate(schema, { notes }) || {}
    setErrors(check)

    if (Object.keys(check).length) {
      return
    }

    const newRequest = await put(`api/v1/requests/${item.request_id}/request_items/${item.id}`, { user_notes: notes })

    if (response.ok) {
      setEditNotes(false)
      setItemsEditing(itemsEditing.filter(id => id !== item.id))
      askItemUpdated({
        ...item,
        user_notes: notes
      })
    }
  }, [put, response, notes])

  // const handleDelivery = useCallback(async () => {
  //   const r = await put(`api/v1/requests/${item.request_id}`, { delivery_required: })
  // })

  return (
    <li style={styles.li}>
      {error ? (
        <div>There was an error saving your data!</div>
      ): null}
      <div style={styles.header}>
        <div style={styles.left}>
          <Text as="Paragraph" tag="p" style={{ display: 'flex', alignItems: 'center' }}>
            {item.name}
          </Text>
          {item.quantity > 1 ? (
            <Text as="ParagraphSmall" tag="p" color="secondary" style={{ paddingLeft: 2 }}>
              Quantity : {item.quantity}
            </Text>
          ): null}
          {item.user_notes && !editNotes ? (
            <Text as="ParagraphSmall" tag="p" color="secondary" style={{ paddingLeft: 2 }}>
              Notes : {item.user_notes}
            </Text>
          ): null}
        </div>
        <div style={styles.right}>
          {editNotes ? (
            <ButtonGroup>
              <Button onClick={() => {
                setItemsEditing(itemsEditing.filter(id => id !== item.id))
                setEditNotes(false)
              }} variant="neutral">Cancel</Button>
              <Button
                onClick={handleSave}
                variant="destructive"
              >
                Save
              </Button>
            </ButtonGroup>
          ) : (
            <Button
              onClick={() => {
                setItemsEditing([...itemsEditing, item.id])
                setEditNotes(true)
              }}
              variant="neutral"
            >
              {item.user_notes ? 'Edit Notes' : 'Add Notes'}
            </Button>
          )}
        </div>
      </div>
      {editNotes ? (
        <div style={styles.notes}>
          <TextArea error={errors.notes} label="Notes" name={`${item.id}-notes`} onChange={e => setNotes(e.target.value)} value={notes} />
        </div>
      ) : null}
    </li>
  )
}

const getItemStyles = tokens => ({
  li: {
    borderBottom: `1px solid ${tokens.Color.Neutral300}`,
    padding: 10,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  notes: {
    paddingTop: 10
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }
})

