import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import useFetch from 'use-http'
import _memoize from 'lodash/memoize'
import moment from 'moment'
import { Link, Route, Switch, useRouteMatch } from "react-router-dom"
import { Button } from '@kyper/button'
import { Text } from '@kyper/text'
import { MessageBox } from '@kyper/messagebox'

import { myAsksLoaded } from '../actions/MyAsks'

import { Table } from './shared/Table'

import CheckPermissions from './Requests/CheckPermissions'


import { RequestCard } from './shared/RequestCard'

import { ASK_TIME_FRAME, ASK_TIME_MESSAGE, checkAskDates } from '../utils/asks'
import { checkForMobile } from '../utils/browser'

const _checkAskDates = _memoize(checkAskDates)

const AskHome = ({ myAsks, myAsksLoaded }) => {
  const isMobile = checkForMobile()
  const { get, response: myAsksResponse, loading, error, } = useFetch('/api/v1/requests', { cachePolicy: 'no-cache' })
  const { delete: deleteAsk, response } = useFetch({ cachePolicy: 'no-cache' })
  const { url } = useRouteMatch();
  const [ permissionError, setPermissionError ] = useState(false)
  const eligibilityStatus = _checkAskDates(myAsks)
  // const eligibilityStatus = ASK_TIME_FRAME.VALID
  const pending = myAsks.filter(ask => ask.status === 'pending')
  const sorted = myAsks.sort((a, b) => b.id - a.id)

  const handleDeletePending = useCallback(async (id) => {
    await deleteAsk(`/api/v1/requests/${id}`)

    if (response.ok) {
      loadMyAsks()
    }
  }, [deleteAsk, response])

  useEffect(() => { loadMyAsks() }, []) // componentDidMount

  async function loadMyAsks() {
    const res = await get()
    if (myAsksResponse.ok) {
      myAsksLoaded(res)
    }
  }

  if (error) {
    return (
      <React.Fragment>
        Oops, something went wrong.
      </React.Fragment>
    )
  }

  return (
    <div>
      {loading ? (
        <React.Fragment>
          Loading...
        </React.Fragment>
      ) : null}
      <CheckPermissions onError={() => { setPermissionError(true) }}/>
      {eligibilityStatus !== ASK_TIME_FRAME.VALID ? (
        <MessageBox title="Error" variant="error" style={{ marginBottom: 20 }}>
          {ASK_TIME_MESSAGE[eligibilityStatus]}
        </MessageBox>
      ) : null}

      {eligibilityStatus === ASK_TIME_FRAME.VALID && !permissionError ?(
        <Link to={`${url}/new`} style={{ textDecoration: 'none' }}>
          <Button variant="primary" style={{ marginBottom: 20 }}>
            Create New Ask
          </Button>
        </Link>
      ): null}

      <Table>
        <thead>
          <tr>
            <th>ID</th>
            {!isMobile ? (
              <th>Created Date</th>
            ) : null}
            <th>Days Since</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sorted.map(row => {
            const created = moment(row.created_at)

            return (
              <tr key={row.id}>
                <td>{row.id}</td>
                {!isMobile ? (
                  <td>{created.format('MM/DD/YYYY')}</td>
                ) : null}
                <td>{moment().startOf('day').diff(created.startOf('day'), 'days')}</td>
                <td>{row.status}</td>
                <td>
                  {row.status === 'pending' || row.status === 'denied' ? (
                    <Link to={`${url}/edit/${row.id}`} style={{ textDecoration: 'none' }}>
                      <Button variant="primary">
                        Edit
                      </Button>
                    </Link>
                  ) : null}
                </td>
              </tr>
            )
          })}
          <tr>
          </tr>
        </tbody>
        {}
      </Table>
    </div>

  )
}

const mapStateToProps = (state) => ({
  myAsks: state.myAsks.items
})

const mapDispatchToProps = dispatch => ({
  myAsksLoaded: (payload) => dispatch(myAsksLoaded(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(AskHome)